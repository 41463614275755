<template>
  <div class="userinfo-container">
    <header class="userinfo-header">
      账号信息
    </header>
    <body v-loading="userLoading" class="userinfo-body">
      <div class="body-frist">
        <div class="userinfo-avatar"> 
          <div class="avatar-left">
            头像
            <Upload class="avatar-img" :imgUrl="avatar ||defaultAvatar" :title="'修改头像'" @onSuccess="changeAvatar" :width="'64px'"></Upload>
          </div>
          
        </div>
        <div class="general-form">
          <div class="general-form-left">
            <span>用户名</span> 
            <el-input :disabled="userDisabled" v-model="name"  @input="nameChange = 1" style="width:200px" :class="{'input-border':!userDisabled}">
            </el-input>
          </div>
          <el-button class="right-button" v-if="userDisabled" size="mini"  type="text" @click="userDisabled = !userDisabled">编辑</el-button>
          <el-button class="right-button" v-else size="mini"  type="text" @click="changeName">保存</el-button>
        </div>
      </div>
      <div class="body-second">
        <div class="general-form">
          <div class="general-form-left">
            <span>密码</span> 
            <el-input :value="pass_set?'********':'未设置'">
            </el-input>
          </div>
          <el-button  class="right-button" size="mini"  type="text" @click="openPass">{{pass_set?'修改':'设置密码'}}</el-button>
        </div>
        <div class="general-form">
          <div class="general-form-left">
            <span>手机号</span>
            <el-input v-model="phone" disabled >
            </el-input>
          </div>
          <el-button class="right-button" v-if=" third_user_id || wechat_status" size="mini"  type="text"  @click="showPhone = true">
            {{phone == '暂无' ? '绑定手机' : '修改手机'}}
          </el-button>
        </div>
        <div class="general-form">
          <div class="general-form-left">
            <span>邮箱</span> 
            <el-input v-model="email" disabled>
            </el-input>
          </div>
          <el-button class="right-button" size="mini"  type="text" @click="showEmail = true">
            {{ email == '暂无' ? '绑定邮箱' : '修改邮箱'}}
          </el-button>
        </div>
      </div>
      <div class="body-thirdly">
        <div class="general-form">
          <div class="general-form-left">
            <span>微信</span> 
            <el-input :value="wechat_status ? '已绑定' : '未绑定'">
            </el-input>
          </div>
          <el-button class="right-button" v-if="third_user_id || phone !== '暂无' " size="mini"  type="text" @click="showWechat = true">{{wechat_status ? '解除绑定' : '点击绑定'}}</el-button>
        </div>
      </div>

      <div class="body-fourthly">
        <div class="general-form">
          <div class="general-form-left">
            <span>版本类型</span>
            <div class="private" v-if="saasVersion !== 3">
              <img src="https://static.bimcc.com/openim/1665394138262E893DFB2849467F23F73A87392333DB7.png">
              <span v-if="(saas_version_apply && saas_version_apply.length && saas_version_apply[0].checked == 3) ">(已被撤销)</span>
              <span v-if="(saas_version_apply && saas_version_apply.length && saas_version_apply[0].checked == 2)">(已被驳回)</span>
            </div>

              <img v-else src="https://static.bimcc.com/openim/16653941200406E2CB2FA51A9A4627FDCD8345F15903F.png">
          </div>
          <el-button class="right-button" @click="openUpdate" size="mini"  type="text" v-if="
                (saasVersion == 1 && is_company && master_company_id == saas.id && !isVersionApply) || 
                (saas_version_apply && saas_version_apply.length &&saas_version_apply[0].checked == 3) ||
                (saas_version_apply && saas_version_apply.length&&saas_version_apply[0].checked == 2)" >升级为企业版</el-button>
          <span v-if="saas_version_apply && saas_version_apply.length && saas_version_apply[0].checked == 0  "> 审核中</span>
        </div>
      </div>
    </body>

<!-- 更改密码 -->
    <el-dialog
      width="400px"
      :visible.sync="showPass"
      :title="pass_set?'修改':'设置密码'" >
      <el-form label-position="top">
        <el-form-item v-if="pass_set" label="原密码">
          <!-- <span style="color: red">新注册用户原密码为123456</span> -->
          <el-input
            ref="origin"
            type="password"
            v-model="oldpassword"
            show-password
            auto-complete="new-password"
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input
            type="password"
            v-model="password"
            placeholder="请输入新的密码"
            show-password
            auto-complete="new-password"
            @input="checkPwd"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input
            type="password"
            v-model="confirmation"
            placeholder="请重复新的密码"
            show-password
            auto-complete="new-password"
            @input="checkConfirm"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="showPass = false" >取消</el-button>
        <el-button type="primary" @click="changePass">确定</el-button>
      </div>
    </el-dialog>

<!-- 更改手机号 -->
    <el-dialog
      :visible.sync="showPhone"
      width="400px"
      @closed="closePop">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span> 
            {{ phone == '暂无' ? '绑定手机号' : '您已绑定手机号 , 请先解绑手机号'}}
            </span>
          <!-- <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
            <i class="el-icon-full-screen"></i>
          </div> -->
        </div>
      </template>
      <el-form label-position="top">
        <el-form-item label="手机号">
          <!-- <span style="color: red">新注册用户原密码为123456</span> -->
        <el-input
          v-model="tmp_phone"
          :disabled="phone == '暂无' && loading_time == 0 ? false : true"
          placeholder="请输入您的手机号"
        ></el-input>
        </el-form-item>
        <el-form-item label="验证码" class="phone-code">
          <el-input
            v-model="code"
            placeholder="请输入验证码"
            type="number"
            limit="5"
            style="width: 232px"
          ></el-input>
          <el-button
          style="margin-left:5px"
            @click="getPhoneCode"
            type="primary"
            :disabled="loading_time > 0 ? true : false"
            >获取验证码
            <span v-if="loading_time > 0">{{ loading_time }}s</span></el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="closePop" >取消</el-button>
        <el-button type="primary" @click="changePhone">确定</el-button>
      </div>
    </el-dialog>

    <!-- 修改邮箱 -->
    <el-dialog
      :visible.sync="showEmail"
      width="400px"
      @closed="closePop"
      draggable>
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span> 
            {{email == '暂无' ? '绑定邮箱' : '您已绑定邮箱 , 请先解绑邮箱'}}
            </span>
        </div>
      </template>

      <el-form label-position="top">
        <el-form-item label="邮箱">
        <el-input
          v-model="tmp_email"
          :disabled="email == '暂无' && loading_time == 0 ? false : true"
          placeholder="请输入您的邮箱"
        ></el-input>
        </el-form-item>
        <el-form-item label="验证码" class="phone-code">
          <el-input
            v-model="code"
            placeholder="请输入验证码"
            type="number"
            limit="5"
            style="width: 232px"
          ></el-input>
          <el-button
          style="margin-left:5px"
            @click="getEmailCode"
            type="primary"
            :disabled="loading_time > 0 ? true : false"
            >获取验证码
            <span v-if="loading_time > 0">{{ loading_time }}s</span></el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="closePop" >取消</el-button>
        <el-button type="primary" @click="changeEmail">确定</el-button>
      </div>
    </el-dialog>

<!-- 二维码 -->
     <el-dialog
      width="430px"
      :visible.sync="showWechat"
      @closed='closeWechat()'
      style="text-align: center">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span> 
              {{wechat_status ? '扫描二维码解绑微信' : '扫描二维码绑定微信'}}
            </span>
        </div>
      </template>
      <div style="display: flex;flex-direction: column;align-items: center;padding-bottom: 16px;">
        <div id="qrcode" ref="qrcode" style="width:400px;height:400px;display:none"/>
        <img :src="wechat_qrcode" style="width: 200px; height: 200px" @click="getWechatQrcode()" />
        <p style="margin-top:10px">点击图片可刷新二维码</p>
      </div>
    </el-dialog>

  </div>

</template>

<script>
// import QRCode from 'qrcodejs2'
import { dataInterface } from '@/apis/data/saasMapi'
import { userVersionApply, myUserInfo } from '@/api/saasManage'
import { getToken } from '@/utils/tools'
import { baseUrl } from '@/libs/util';
import { userInfo } from '@/api/user'
import {
  sendMailCode,
  changeMail,
  sendPhoneCode,
  changePhone1,
  changePassword,
} from '@/api/userInfo'
import Upload from '../companyConfig/components/upload.vue'
import axios from 'axios'
import { mapState } from 'vuex';
export default {
  components: { Upload},
  inject: [],
  props: {},
  data() {
    const phoneReg =
      /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*()_.]+)$)^[\w~!@#$%^&*()_.]{8,16}$/
    return {
      defaultAvatar: require('@/assets/images/Group.png'),
      userDisabled:true,
      isVersionApply:false, // 判断是否已经申请了企业
      userLoading: false,
      name: '',
      email: '',
      phone: '',
      avatar: '',
      wechat_status: '',
      is_company: '',
      saas_version_apply: [],
      saas_company_apply: [],
      pass_set: '',
      user_id: null,
      master_company_id:'',
      third_user_id:null,

      tmp_email: '',
      tmp_phone: '',
      code: '',

      tmp_company_name: '',
      tmp_company_type: '',

      showMask: 0,
      nameChange: 0,
      showEmail: false,
      showPhone: false,
      showPass: false,
      showWechat: false,
      showUpdate: false,

      wechat_qrcode: '',
      loading_time: 0,
      timer: '',
      check_timer: '',

      oldpassword: '',
      password: '',
      confirmation: '',
      attention: '',
      attention_color: 'black',
      token: getToken(),
      file: null,
      origin: null,
      companyType: null,
      companyName: null,
      phoneReg,
      baseURL: baseUrl(),
      showQr:false,
      saas:null,
    }
  },
  watch: {
    showWechat: {
      handler(data) {
        if (data) {
          this.getWechatQrcode()
        }
      },
    },
    loading_time: {
      handler(value) {
        if (value > 0) {
          this.timer = setTimeout(() => {
            this.loading_time = value - 1
          }, 1000)
        } else {
          this.loading_time = value
          clearTimeout(this.timer)
        }
      },
    },
  },
  computed: {
    ...mapState(['saasVersion']),
  },
  mounted() {
    this.is_company = localStorage.getItem('is_company')
    this.saas = JSON.parse(localStorage.getItem('saas'))
    this.getData()
  },
  methods: {
    closeWechat(){
      this.showWechat = false
      this.getData()
    },
    goCompany() {
      this.$emit('goCompany')
    },
    getData() {
      // todo 以前写的奇怪的逻辑 有时间更改全部逻辑
      this.userLoading = true
      myUserInfo().then((res) => {
        this.userLoading = false
        if (res.data && res.data.code !== 200) {
          return false
        }
        this.form = res.data.data
        let data = res.data.data
        this.name = data.name
        this.email = data.email ? data.email : '暂无'
        this.phone = data.phone ? data.phone : '暂无'
        this.master_company_id = data.master_company_id
        this.tmp_email = data.email
        this.tmp_phone = data.phone
        this.avatar = data.avatar ? data.avatar.trim() : ''
        this.wechat_status = data.wechat_status
        this.saas_version_apply = data.saas_version_apply
        this.pass_set = data.set_password
        this.user_id = data.id
        this.third_user_id = data.third_user_id
        if(data.saas_version_apply && data.saas_version_apply.length){
          this.isVersionApply = true
        }else{
          this.isVersionApply = false
        }
      })
    },

    getWechatQrcode() {
      let user_id = JSON.parse(localStorage.getItem('userInfo')).id
      axios.get(`https://y.bimcc.com/api/wechat/qrcode/bindingSaasQrcode?token=${user_id}`).then(res=>{
        this.wechat_qrcode = res.data.data.url
      })
    },

    changeAvatar(data) {
        this.userLoading = true
        this.avatar = data
        let param = {
          object_uuid: '844a47c6-6380-46dd-bc4a-d73b6245175f',
          view_uuid: 'view6286fc04304f3',
          __method_name__: 'updateData',
          transcode: 0,
          avatar: this.avatar,
          data_id: this.user_id,
        }
        dataInterface(param).then(() => {
          userInfo(getToken()).then(res=>{
            if(res.data.code==200){
              this.$store.commit('setUserInfo',res.data.data)
              this.userLoading = false
            }
          })
           this.userLoading = false
        })
      
    },

    openPass() {
      if (
        (!this.phone || this.phone == '暂无') &&
        (!this.email || this.email == '暂无')
      ) {
        this.$message.info('您尚未绑定手机号或者邮箱 , 无法设置密码')
      } else {
        this.showPass = !this.showPass
      }
    },

    openUpdate() {
      if(this.phone === '暂无'){
        this.$message.error('请先绑定手机号！')
        return
      }
      
      this.userLoading = true
      userVersionApply().then((res)=>{
        if (res.data && res.data.code !== 200) {
          this.$message.error(res.data.msg)
          return false
        }
        this.$message.success('操作成功,请等待申请通过！')
        this.getData()
      })
      this.userLoading = false
    },

    closePop() {
      this.loading_time = 0
      this.showPhone = false
      this.showEmail = false
    },

    changeEmail() {
      if (!this.code) {
        this.$message.error('请输入验证码')
        return
      }
      this.userLoading = true
      let data = {
        email: this.tmp_email,
        code: this.code,
      }
      changeMail(data).then((res) => {
        if (res.data.code == 200) {
          if (this.email == '暂无') {
            this.email = this.tmp_email
          } else {
            this.email = '暂无'
            this.tmp_email = ''
          }
          this.code = ''
          this.loading_time = 0
          this.$message.success('操作成功')
          this.showEmail = false
        }
        if (this.email && this.email != '暂无') {
          this.showEmail = false
        }
        this.userLoading = false
      })
    },

    getEmailCode() {
      if (!this.tmp_email) {
        this.$message.error('email必填！')
        return
      }
      this.loading_time = 30
      sendMailCode({ email: this.tmp_email }).then((res) => {
        if (res.data.code != 200) {
          this.loading_time = 0
        }
      })
    },

    getPhoneCode() {
      if (!this.tmp_phone) {
        this.$message.error('手机号必填!')
        return
      }
      this.loading_time = 30
      sendPhoneCode({ phone: this.tmp_phone }).then((res) => {
        if (res.data.code != 200) {
          this.loading_time = 0
        }
      })
    },

    changePhone() {
      if (!this.code) {
        this.$message.error('请输入验证码')
        return
      }
      this.userLoading = true
      let data = {
        phone: this.tmp_phone,
        code: this.code,
      }
      changePhone1(data).then((res) => {
        if (res.data.code == 200) {
          if (this.phone == '暂无') {
            this.phone = this.tmp_phone
          } else {
            this.phone = '暂无'
            this.tmp_phone = ''
          }
          this.code = ''
          this.loading_time = 0
          this.showPhone = false
          this.$message.success('操作成功')
        }
        if (this.phone && this.phone != '暂无') {
          this.showPhone = false
        }
        this.userLoading = false
      })
    },

    checkPwd() {
      if (!this.phoneReg.test(this.password)) {
        this.attention = '密码必须为8-16位字母、数字、字符组合'
        this.attention_color = 'red'
        return false
      } else {
        this.attention = ''
        return true
      }
    },
    checkConfirm() {
      if (!this.password || !this.confirmation) {
        this.attention = '新密码不能为空'
        this.attention_color = 'red'
      } else if (this.password != this.confirmation) {
        this.attention = '两次新密码输入不一致'
        this.attention_color = 'red'
      } else {
        this.attention = '校验成功'
        this.attention_color = 'green'
        return true
      }
      return false
    },

    changePass() {
      if (!this.checkConfirm()) {
        this.$message.error(this.attention)
        return false
      }
      this.userLoading = true
      const data = {
        oldpassword: this.oldpassword,
        password: this.password,
        confimation: this.confirmation,
      }
      changePassword(data).then((res) => {
        if (res.data.code != 200) {
          this.$ref.origin.focus()
        } else {
          this.pass_set = 1
          this.showPass = false
          this.$message.success('操作成功!')
        }
        this.userLoading = false
      })
    },

    changeName() {
      if (!this.name.length) {
        this.$message.error('昵称不能为空')
      } else {
        this.userLoading = true
        let param = {
          object_uuid: '844a47c6-6380-46dd-bc4a-d73b6245175f',
          view_uuid: 'view6286fc04304f3',
          __method_name__: 'updateData',
          transcode: 0,
          name: this.name,
          data_id: this.user_id,
        }
        dataInterface(param).then((r) => {
          userInfo(getToken()).then(res=>{
            if(res.data.code==200){
              this.$store.commit('setUserInfo',res.data.data)
              this.userLoading = false
            }
          })
          this.userDisabled = true
          this.userLoading = false
          if (r.status && r.status !== 200) {
            return false
          }
          this.nameChange = 0
        })
      }
    },
  },
}
</script>
<style scoped lang="less">
.phone-code{
  :deep(.el-form-item__content){
    display: flex;
    justify-content: flex-start;
    .el-input{
      flex: 1;
      margin-right: 30px;
    }
  }
}
:deep(.el-input__inner){
  background-color: #fff !important;
  color: #606266 !important;
}
.input-border{
  border-radius: 4px;
  border: 2px solid #D0E2FF;
}
.userinfo-container{
  width: 880px;
  margin-left: 24px;
  height: 551px;
  background: #FFFFFF;
  border-radius: 8px;
  .userinfo-header{
    width: 880px;
    height: 48px;
    background: #FFFFFF;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2A2F3D;
    font-size: 14px;
    border-bottom: 1px solid #F2F4F7;
    line-height: 48px;
    padding: 0 24px;
    box-sizing: border-box;
    border-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .userinfo-body{
    width: 100%;
    height: 100%;
    padding: 24px 40px;
    box-sizing: border-box;
    .body-frist{
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #F2F4F7;
      .userinfo-avatar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .avatar-left{
          width: 146px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4E5466;
          .avatar-img{
            width: 64px;
            height: 64px;
            background: #E6E9F0;
            border-radius: 32px;
            :deep(.el-upload){
              border-radius: 32px;
            }
          }
        }
      }
    }
   .body-second{
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #F2F4F7;
    }
    .body-thirdly{
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #F2F4F7;
    }
    .body-fourthly{
      width: 100%;
      padding-bottom: 20px;
    }
    .general-form{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .general-form-left{
        display: flex;
        align-items: center;
        >span{
          width: 60px;
        }
        >img{
          width: 68px;
          height: 32px;
          padding-left: 20px;
        }
        .private{
          display: flex;
          align-items: center;
          >img{
            width: 68px;
            height: 32px;
            padding-left: 20px;
          }
          >span{
            color: #f56c6c;
            margin-left:8px;
          }
        }
        :deep(.el-input){
          width: 200px;
          .el-input__inner{
            border: none;
            width: 200px;
            padding-left: 23px;
          }
        }
      }
    }
  }
}
.right-button{
  padding:7px 16px;
}
.right-button:hover{
  background: #F2F4F7;
  border-radius: 4px;
}
</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>

