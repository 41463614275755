<!--
 * @Author: zx
 * @Date: 2022-05-19 14:05:38
 * @LastEditTime: 2023-02-08 09:28:32
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="block">
    <div class="personalcenter">
      <leftTab ref="leftTab" @changeValue="changeValue" @updateCompany="upDateCompany"/>
      <Userinfo v-if="showValue === 1"></Userinfo>
      <Userapply ref="userapply" v-if="showValue === 2" @changeUnread='changeUnread'></Userapply>
      <CompanyConfig v-if="showValue === 3"></CompanyConfig>
      <Domain v-if="showValue === 4"></Domain>
    </div>
  </div>
</template>
<script>
  import leftTab from './components/leftTab/index.vue'
  import Userapply from'./components/userapply/index.vue'
  import Userinfo from './components/userinfo/index.vue'
  import Domain from './components/domain/index'
  import CompanyConfig from "./components/companyConfig/config.vue";

  export default {
    components: {
      leftTab,
      Userapply,
      Userinfo,
      Domain,
      CompanyConfig,
    },
    inject: [],
    props:{
      
    },
    data(){
      return {
        activeName: 'userapply',
        isCompany:null,
        version_id:null,
        showValue:1,
      }
    },
    mounted() {
      this.upDateCompany()
      let url = location.href
      if(url.includes('name=apply')){
        this.showValue = 2
        this.$refs.leftTab.onActive({
          icon:'iconqiye2',
          label:'企业申请管理',
          value:2,
        })
        
        // setTimeout(()=>{
        //   this.$refs.userapply.tabClick({name:'1'}) 
        // },500)
      
        this.$nextTick(()=>{
          this.$refs.userapply.tabClick({name:'1'}) 
        })
        this.$refs.leftTab.getData()
      }
    },
    methods:{
        changeUnread(){
          this.$refs.leftTab.getData()
        },
        changeValue(data){
          this.showValue = data.value
        },
        goCompany(){
          this.activeName = "companyConfig"
        },
        upDateCompany(){
          this.isCompany = +localStorage.getItem('is_company')
          this.version_id = localStorage.getItem('saas')? JSON.parse(localStorage.getItem('saas'))?.version_id : null
        }
    }
  }
</script>
<style rel="stylesheet/scss" lang="less" scoped>
.block {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
   
  .personalcenter {
    display: flex;
    justify-content: center;
    width: 100%;
    // height: 100%;
  }
}
 .el-tabs--top{
  height: 100%;
  :deep(.el-tabs__content){
  height: calc(100% - 77px) !important; 
  
  .el-tab-pane {
      height: 100%;
  }
}
}

</style>
