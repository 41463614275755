<template>
  <div class="config-container" v-loading='configLoading'>
    <div class="config-frist">
      <header class="config-header">
        企业配置
      </header>
      <el-form :model="formData" label-position="top">
        <el-form-item label="版本类型">
          <div style="height:32px">
            <img v-if="formData.version=='个人版'" src="https://static.bimcc.com/openim/1665394138262E893DFB2849467F23F73A87392333DB7.png" class="version-img">
            <img v-else src="https://static.bimcc.com/openim/16653941200406E2CB2FA51A9A4627FDCD8345F15903F.png" class="version-img">
          </div>
        </el-form-item>
        <el-form-item label="企业码">
          <div class="code-class">
            <span v-if="!showCode"> {{codeLength()}} <i class="iconfont icona-yanjingyincang" @click="showCode=!showCode"></i></span>
            <span v-else>{{formData.code}} <i class="iconfont icona-yanjingxianshi" @click="showCode=!showCode"></i></span>
            <div v-if="showCode" @click.stop="copyCode">复制</div>
          </div>
          
          <!-- <el-input class="code-class" v-model="formData.code" show-password></el-input> -->
        </el-form-item>
        <el-form-item label="平台名称">
          <el-input class="name-class" v-model="formData.system_name" placeholder="请输入系统名称" maxlength="10"
              @input="setSystemName">
          </el-input>
        </el-form-item>
        <div style="display:flex">
          <el-form-item label="企业类型">
            <el-select v-model="formData.company_type" placeholder="请选择企业类型" style="width: 200px;"
              @change="setCompanyType">
              <el-option v-for="item in options" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="企业名称" style="margin-left:24px">
            <el-input class="name-class" style="margin-left" v-model="formData.name" placeholder="请输入企业名称" maxlength="10" @input="setCompanyName">
            </el-input>
          </el-form-item>
        </div>

      </el-form>
    </div>
    <!-- 暂时没用隐藏 -->
    <!-- <div class="config-second">
      <el-form :model="formData" label-position="top">
        <el-form-item label="使用全屏背景图" >
          <el-radio v-model="formData.useDefaultLoginBack" label="1" @change='setFullScreenBackground'>使用</el-radio>
          <el-radio v-model="formData.useDefaultLoginBack" label="0" @change='setFullScreenBackground'>不使用</el-radio>
        </el-form-item>
        <div style="display:flex;justify-content: space-between;width:620px">
           <el-form-item label="登录页Logo">
            <Upload style="width:100px;height:100px" :dataId="formData.id" :imgUrl="formData.index_logo" :title="'修改登录页Logo'" @onSuccess="onSuccessIndex"></Upload>
          </el-form-item>
          <el-form-item label="首页Logo">
            <Upload style="width:100px;height:100px" :dataId="formData.id" :imgUrl="formData.logo" :title="'修改首页Logo'" @onSuccess="onSuccessLogo"></Upload>
          </el-form-item>
          <el-form-item label="登录背景">
            <Upload style="width: 100px;height: 100px;" :dataId="formData.id" :imgUrl="formData.login_back" :title="'修改登录背景图'" @onSuccess="onSuccessBack"></Upload>
          </el-form-item>
        </div>
      </el-form>
    </div> -->
  </div>
</template>
<script>
import { dataInterface } from '@/apis/data/saasMapi';
import { myCompanyInfo } from '@/api/saasManage'
// import Upload from './components/upload.vue'
import ClipboardJS from 'clipboard'
export default {
    components: { 
    },
    data(){
      return {
        showCode:false,
        configLoading:false,
        formData:{},
        object_uuid: "object627e0f5709c29", // uuid
        view_uuid: 'view62873a214d0d6',
        showSystemNameSaveBtn: false,
        showCompanyTypeSaveBtn: false,
        showCompanyNameSaveBtn:false,
        options:["监理","施工","甲方","设计"]
      }
    },

    mounted() {
      this.getDetails()
    },
    methods:{
      codeLength(){
        let aaa = ''
        if(this.formData.code){
          for(let i = 0; i<this.formData.code.length;i++){
            aaa += '●'
          }
        }
        return aaa
      },
      copyCode(e){
        const clipboard = new ClipboardJS(e.target, { text: () => this.formData.code})
        clipboard.text()
        clipboard.on('success', () => {
          this.$message.success('复制成功')
          this.$nextTick(() => {
            clipboard.destroy()
          })
        })
        clipboard.on('error', () => {
          this.$message.error('该浏览器不支持自动复制')
          this.$nextTick(() => {
            clipboard.destroy()
          })
        })
        clipboard.onClick(e)
      },

      getDetails(){
        this.configLoading = true;
        myCompanyInfo().then((res) => {
          console.log(res)
          if(res.data.code !== 200){
            return
          }
          let data = res.data.data
          this.formData = data
          this.formData.can_join = Boolean(data.can_join)
          this.formData.useDefaultLoginBack = data.useDefaultLoginBack + ''
          this.formData.version = data.version.name
          this.configLoading = false
        }).catch(() => {
          this.$message.error('获取企业信息失败')
        })
      },
      onSuccessLogo(url){
        this.uploadFunction(url,'logo')
      },
      onSuccessBack(url){
        this.uploadFunction(url,'login_back')
      },
      onSuccessIndex(url){
        this.uploadFunction(url,'index_logo')
      },
      uploadFunction(url,type){
        let params = {
          object_uuid: 'object627e0f5709c29',
          view_uuid: 'view62873a214d0d6',
          __method_name__: 'updateData',
          transcode: 0,
          data_id: this.formData.id,
        }
        params[type] = url
        dataInterface(params).then((r) => {
          if(r.data.code === 200){
            this.getDetails()
          }             
        })
      },
      // 设置全屏
      setFullScreenBackground(value){
        let params = {
          __method_name__: "updateData",
          object_uuid: this.object_uuid,
          data_id: this.formData.id,
          'useDefaultLoginBack': Number(value),
        }
        dataInterface(params)
      },
      // 保存系统名称
      setSystemName(){
        let reg = /^[\u4E00-\u9FA5A-Za-z0-9]{2,20}$/;
        let name = this.formData.system_name;
        if (!reg.test(name)) {
          this.$message.warning('仅限使用中文英文数字')
          return;
        }
        let params = {
          __method_name__: "updateData",
          object_uuid: this.object_uuid,
          data_id: this.formData.id,
          'system_name': this.formData.system_name,
        }
        dataInterface(params).then(
          (res) => {
            if(res.data.code === 200){
              this.showSystemNameSaveBtn = false;
            }
          }
        )
      },

      // 保存企业类型
      setCompanyType(){
        let params = {
          __method_name__: "updateData",
          object_uuid: this.object_uuid,
          data_id: this.formData.id,
          'company_type': this.formData.company_type,
        }
        dataInterface(params).then(
          (res) => {
            if(res.data.code === 200){
              this.showCompanyTypeSaveBtn = false;
            }
          }
        )
      },
      setCompanyName(){
        let params = {
          __method_name__: "updateData",
          object_uuid: this.object_uuid,
          data_id: this.formData.id,
          name: this.formData.name,
        }
        dataInterface(params).then(
          (res) => {
            if(res.data.code === 200){
              this.showCompanyNameSaveBtn = false;
            }
          }
        )
      },
    }
}

</script>
<style scoped lang='less'>
  .config-container{
    width: 880px;
    margin-left: 24px;
    .config-frist{
      width: 880px;
      height: 420px;
      background: #FFFFFF;
      border-radius: 8px;
      :deep(.el-form){
        padding:24px 40px;
        .el-form-item__label{
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2A2F3D;
          padding:0;
        }
        .el-form-item{
          margin-bottom: 16px;
        }
        .code-class{
          display: flex;
          span{
            width: 351px;
            height: 32px;
            background: #F2F4F7;
            border-radius: 4px;
            border: 1px solid #DCDFE6;
            display: flex;
            box-sizing: border-box;
            padding: 0 15px;
            justify-content: space-between;
          }
          div{
            color: #409EFF;
            padding:0 8px;
            margin-left: 8px;
            border-radius: 4px;
          }
          div:hover{
            background: #F2F4F7;
            border-radius: 4px;
          }
        }
        .name-class{
          width: 351px;
          height: 32px;
          background: #F2F4F7;
          border-radius: 4px;
        }
      }
      .config-header{
        height: 48px;
        background: #FFFFFF;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2A2F3D;
        font-size: 14px;
        border-bottom: 1px solid #F2F4F7;
        line-height: 48px;
        padding: 0 24px;
        border-radius: 8px;
        box-sizing: border-box;
      }
      .version-img{
        width: 68px;
        height: 32px;
      }
    }
    .config-second{
      width: 880px;
      margin-top: 12px;
      background: #FFFFFF;
      border-radius: 8px;
      :deep(.el-form){
        padding:24px 40px;
        .el-form-item__label{
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2A2F3D;
          padding:0;
        }
      }
    }
  }
</style>