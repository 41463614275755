<template>
  <div class="left-container">
    <header>
      <div class="header">
        <img :src="userInfo.avatar || defaultAvatar" class="header-avatar">
        <span class="header-name">{{userInfo.name}}</span>
      </div>
    </header>
    <nav class="nav">
      <div v-for="(item,index) in dataList" :key="index" class="nav-div" :class="{'active':item.value === activeValue}" @click="onActive(item)">
        <div class="nav-left" v-if="showTab(item.value)">
          <i class="iconfont" :class="item.icon"></i>
          <span>{{item.label}}</span>
        </div>

        <div v-if="item.value==2 && unreadNum" class="unread-num">{{unreadNum}}</div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import { dataInterface } from '@/apis/data/saasMapi'
import {myManageCompany} from '@/api/saasManage'
export default {
  components: {},
  inject: [],
  props: {},
  data() {
    return {
      unreadNum:0,
      defaultAvatar: require('@/assets/images/Group.png'),
      activeValue:1,
      dataList:[{
        icon:'icona-yonghugeren',
        label:'账号信息',
        value:1,
      },{
        icon:'iconqiye2',
        label:'企业申请管理',
        value:2,
      },{
        icon:'icona-peizhijindu',
        label:'企业配置',
        value:3,
      },
      //展示注释掉
      // {
      //   icon:'icona-wangluoyuming',
      //   label:'独立域名管理',
      //   value:4,
      // }
    ],
      loading: true,
      name: '',
      avatar: '',
      isSuper:false,
    }
  },
  mounted() {
    this.getData()
    this.isSuper = JSON.parse(localStorage.getItem('userInfo')).saas_admin
  },
  computed: {
    ...mapState(['userInfo',['userInfo']]),
    params() {
      return JSON.parse(JSON.stringify(this.userInfo));
    },
  },
  methods: {
    onActive(data){
      this.activeValue = data.value 
      this.$emit('changeValue',data)
    },
    showTab(value){
      if(value!==1){
        if(this.isSuper){
          return false
        }
      }
      return true
    },
    getData(){
        myManageCompany().then((res) => {
          if (res.data.code == 200) {
            this.unreadNum = 0
            res.data.data.forEach(r=>{
              if(r.checked === 0){
                this.unreadNum ++
              }
            })
          }
        })
    },
  },
}
</script>
<style scoped lang="less">
.left-container {
  width: 224px;
  height: 296px;
  background: #FFFFFF;
  border-radius: 8px;
  padding-top: 32px;
  box-sizing: border-box;
  .header{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .header-avatar{
      width: 40px;
      height: 40px;
      background: #E6E9F0;
      border-radius: 20px;
    }
    .header-name{
      font-size: 14px;
      margin-top: 8px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2A2F3D;
    }
  }
  .nav{
    padding: 24px 12px;
    box-sizing: border-box;
    .nav-div{
      display: flex;
      height: 40px;
      border-radius: 6px;
      align-items: center;
      padding: 0 13px;
      margin-top: 8px;
      transition: 0.3s;
      justify-content: space-between;

      .nav-left{
        >span{
          padding-left: 8px;
        }
      }

      .unread-num{
        width:16px;
        height:16px;
        line-height: 16px;
        background:#F55858;
        color:white;
        border-radius: 50%;
        font-size: 12px;
        margin-left: 4px;
        display: inline-block;
        text-align: center;
      }
    }
    .nav-div:hover{
      background: #F2F4F7;
    }
    .active{
      background: #E8F0FE !important;
    }
  }
}
</style>
<style lang="less" scoped>
:deep(.el-dialog__body){
  padding: 30px 20px !important;
}
@import "../../../../css/manageAdd.less";
</style>

