<template>
  <div class="domain-container">
    <header class="domain-header">
      独立域名管理
    </header>
    <div class="domain-body">
      <el-button type="primary" class="add-btn" @click="addModal">新增域名申请</el-button>
      <div class="domain-body-table">
        <el-table
          :data="lists"
          v-loading="loading"
          width="100%"
          empty-text="暂无数据"
        >
          <el-table-column prop="domain_name" label="域名名称"></el-table-column>
          <el-table-column prop="archiName" label="绑定架构" align="center">
          </el-table-column>

          <el-table-column prop="system_name" label="平台名称" align="center">
          </el-table-column>
          <el-table-column prop="checked" label="审核状态">
            <template #default="{ row }">
              <el-tag type="success" v-if="row.checked">审核通过</el-tag>
              <el-tag v-else>审核中</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="190" align="center">
            <template #default="{ row }">
              <el-button type="primary" @click="edit(row)">编辑</el-button>
              <el-popconfirm
                title="确定要删除这个域名吗？?"
                class="pl-5"
                confirmButtonText="确认"
                cancelButtonText="取消"
                @confirm="deleteData(row)"
                v-if="!row.checked"
              >
                <template #reference>
                  <el-button type="danger">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      width="400px"
      :visible.sync="createModalOpen"
    >
      <template slot="title">
        <div class="avue-crud__dialog__header">
          <span> 
            {{editTag ? '编辑域名申请' : '新增域名申请'}}
          </span>
        </div>
      </template>
      <el-form v-model="form" label-width="80" label-position="top">
        <el-form-item label="域名名称">
          <el-input
          style="width:100%"
            v-model="form.domain_name"
            placeholder="输入前缀即可,系统会自动加上 .bimcc.com"
          ></el-input>
        </el-form-item>
        <el-form-item label="系统名称">
          <el-input
            style="width:100%"
            v-model="form.system_name"
            placeholder="系统名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定架构">
          <el-select
            style="width:100%"
            class="form-input"
            v-model="form.archiName"
            placeholder="请选择绑定架构"
            > 
            <el-option label="区域一" value="0"   class="form-option">
              <el-tree ref="tree" :data="parentData" default-expand-all node-key="id"  @node-click="handleNodeClick"  :props="defaultProps"/>
            </el-option>
            <!-- <el-option
              v-for="item in archiChooses"
              :value="item.archiID"
              :label="item.archiName"
              :key="item.archiID"
              >{{ item.archiName }}</el-option
            > -->
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="createModalOpen = false">取消</el-button >
        <el-button type="primary" @click="createModal">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getDept } from '@/api/saasManage'
import { dataInterface } from '@/apis/data/saasMapi'
import { Tag, Popconfirm, Dialog, Tree } from 'element-ui'
export default {
  components: {
    'el-tree': Tree,
    'el-tag':Tag,
    'el-popconfirm':Popconfirm,
    'el-dialog': Dialog
  },
  inject: [],
  props: {},
  data() {
    return {
      parentData: [
        {
          name: '顶级菜单',
          id: 0,
          children: [],
        },
      ],
      loading: false,
      editTag: 0,
      form: {
        domain_name: '',
        system_name: '',
        archiID: '',
        archiName:'',
        id:''
      },
      archiName:'',
      archis: [],
      createModalOpen: false,
      lists: [],
      archiChooses: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      type:'',
    }
  },

  mounted() {
    this.getData()
  },
  methods: {
    /**
     * @desc: 下拉树选择的数据
     */
    handleNodeClick(row) {
      // const { id, name } = row
      this.form.archiID = row.id
      this.form.archiName = row.name

      this.form = {
        domain_name:this.form.domain_name,
        system_name:this.form.system_name,
        id:this.form.id,
        archiID:row.id,
        archiName:row.name
      }
    },
    getData() {
      this.loading = true
      const param = {
        object_uuid: 'object6281b200dab37',
        view_uuid: 'view62821ccf586d3',
        __method_name__: 'dataList',
        transcode: 0,
      }
      dataInterface(param).then((res) => {
        if (res.data.code == 200) {
          this.lists = res.data.data.data
          this.loading = false
        }
      })
      getDept().then((res) => {
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.parentData = res.data.data.data;
      })
      .catch(() => {
        this.$message.error(`操作错误`)
      })
    },

    deleteData(data) {
      const param = {
        object_uuid: 'object6281b200dab37',
        view_uuid: 'view62821ccf586d3',
        __method_name__: 'deleteData',
        data_id: data.id,
        transcode: 0,
      }
      dataInterface(param).then(() => {
        this.getData()
      })
    },

    addModal() {
      this.type = 'add'
      this.form = {
        domain_name: '',
        system_name: '',
        archiID: '',
        archiName:'',
      }
      this.createModalOpen = true
    },

    createModal() {
      let method_name
      if (this.type === 'edit') {
        method_name  = 'upDateData'
      }else {
        method_name  = 'createData'
      }
      const param = {
        object_uuid: 'object6281b200dab37',
        view_uuid: 'view62821ccf586d3',
        __method_name__: method_name,
        transcode: 0,
        ...this.form
      }
      if(this.type === 'edit'){
        param.data_id = this.form.id
      }
      dataInterface(param).then(() => {
        this.createModalOpen = false
        this.getData()
      })
    },

    edit(data) {
      this.type = 'edit'
      if (data.checked) {
        this.$message.warning('已审核的域名不允许更改')
      } else {
        this.editTag = data.id
        this.createModalOpen = true
        this.form = {
          archiID: data.archiID,
          domain_name: data.domain_name,
          system_name: data.system_name,
          archiName:data.archiName,
          id:data.id
        }
      }
    },
  },
}
</script>
<style scoped lang="less">
.domain-container {
  width: 880px;
  height: auto;
  background: #FFFFFF;
  border-radius: 8px;
  margin-left: 24px;
  .domain-header{
    width: 880px;
    height: 48px;
    background: #FFFFFF;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2A2F3D;
    font-size: 14px;
    border-bottom: 1px solid #F2F4F7;
    line-height: 48px;
    padding: 0 24px;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .domain-body{
    padding: 24px;
 
  }
  .domain-body{
    padding: 24px;
    .domain-body-table{
      padding: 16px;
      background: #F2F4F7;
      margin-top: 24px;
      :deep(.el-table){
        border-radius: 6px;
      }
    }
  }
}

</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>

