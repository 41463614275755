<template>
 <div class="apply-container">
    <el-tabs @tab-click="tabClick" v-if="is_company" v-model="tab_id">
      <el-tab-pane name="0" height="48px"> <span slot="label" style="diplay:flex">我管理的企业 <div class="unread-num" v-if="unreadNum">{{unreadNum}}</div></span></el-tab-pane>
      <el-tab-pane label="我申请加入的企业" name="1"></el-tab-pane>
    </el-tabs>
    <div v-if="tab_id === '0'" v-loading="applyLoading" class="manage-class">
      <div class="manage-title" v-if="lists.length">{{lists[0].company.name}}</div>
      <el-table
        width="100%"
        style="overflow: hidden"
        :data="lists"
        empty-text="暂无数据"
      > 
        <el-table-column label="申请人">
          <template #default="{ row }">
            <div>{{row.user_info.name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间"></el-table-column>
        <el-table-column prop="checked" label="审核状态">
          <template #default="{ row }">
            <el-tag type="success" v-if="row.checked==1">已通过</el-tag>
            <el-tag type="danger" v-else-if="row.checked==2">已拒绝</el-tag>
            <el-tag v-else>待审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column  label="操作"  width='190px'>
          <template #default="{row}">
            <div v-if="row.checked == 0">
              <el-button type="primary" size='mini' style="margin-right:5px;" @click="editData(row,1)">通过</el-button>
              <el-button type="danger" size='mini' style="margin-right:5px;" @click="editData(row,2)">拒绝</el-button>
            </div>
          <!-- <el-popconfirm title="确认删除吗?" @confirm="deleteData(row)">
            <template #reference>
              <el-button type="danger"  size='small'>删除</el-button>
            </template>
          </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="tab_id === '1'" v-loading="applyLoading" class="apply-class">
      <el-button
      type="primary"
      @click="createModalOpen = true"
      >申请加入企业</el-button
    >
      <div v-for="(item,index) in lists" :key="index" class="apply-tab">
        <div>{{item.company.name}}</div>
        <el-tag type="success" v-if="item.checked==1">已通过</el-tag>
        <el-tag type="danger" v-else-if="item.checked==2">已拒绝</el-tag>
        <el-tag effect="dark" v-else>待审核</el-tag>
      </div>
    </div>

    <el-dialog
      :visible.sync="createModalOpen"
      :title="'申请加入企业'"
      transfer
      width="520px"
    >
      <el-form v-model="form" label-width="80px" label-position="top">
        <el-form-item label="企业码">
          <el-input
            v-model="form.company_id"
            placeholder="请输入企业码"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="createModalOpen = false">取消</el-button>
        <el-button type="primary" @click="createModal">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { dataInterface } from '@/apis/data/saasMapi'
import { TabPane, Tabs, Tag} from 'element-ui'
import { companyCodeUse,myManageCompany,myApplyCompany } from  '@/api/saasManage'

export default {
  components: {
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    'el-tag':Tag,
  },
  inject: [],
  props: {},
  data() {
    return {
      unreadNum:0,
      title: '',
      content: '',
      dialog1: false,
      tab_id: '0',
      is_company: false,
      lists: [],
      applyLoading: false,
      companies: [],
      tmpTab: 0,
      createModalOpen: false,
      form: {
        company_id: '',
        content: '',
      },
      user_id: JSON.parse(localStorage.getItem('userInfo')).id,
    }
  },

  mounted() {
    this.is_company = +localStorage.getItem('is_company')
    if (this.is_company === 0) {
      this.tab_id = '1'
    }
    this.getData()
  },
  methods: {

    createModal() {
      if (!this.form.company_id) {
        this.$message.info('请选择要申请的企业')
        return
      }
      companyCodeUse({code:this.form.company_id}).then(res=>{
        if (res.data.code == 200) {
          this.$message.success('操作成功')
          this.createModalOpen = false
          this.getData()
        }
      })
    },

    editData(row,index) {
      this.applyLoading = true
        const param = {
          object_uuid: 'object627e1c665d6e0',
          view_uuid: 'view6284a4e8907e6',
          __method_name__: 'updateData',
          transcode: 0,
          checked: index,
          data_id: row.id,
        }
        dataInterface(param).then((res) => {
          if (res.data.code == 200) {
            this.$emit('changeUnread')
            this.getData()
          }
        })
      
    },

    getData() {
      //我管理的企业
      this.applyLoading = true
      this.lists = []
      console.log(this.tab_id,'this.tab_id')
      if (this.tab_id === '0') {
        myManageCompany().then((res) => {
          if (res.data.code == 200) {
            if(!this.lists.length) {
              this.lists = res.data.data
              this.applyLoading = false
              this.unreadNum = 0
              this.lists.forEach(r=>{
                if(r.checked === 0){
                  this.unreadNum ++
                }
              })
            }
  
          }
        }).catch(()=>{
          this.$message.error('获取我管理的企业失败')
        })
      } else {
        // 我申请加入的企业
        myApplyCompany().then((res) => {
          if (res.data.code == 200) {
            this.lists =[]
            this.lists = res.data.data
            console.log(this.lists)
            this.applyLoading = false
          }
        })
      }
    },

    tabClick(data) {
      console.log(data)
      this.tab_id = data.name
      this.getData()
    },
    changeTab(data) {
      this.tmpTab = data
      this.form.company_id = ''
    },
  },
}
</script>
<style scoped lang="less">
.apply-container{
  width: 880px;
  height: auto;
  background: #FFFFFF;
  border-radius: 8px;
  margin-left: 24px;
  padding: 0px 24px 24px 24px;
  box-sizing: border-box;
  :deep(.el-tabs__header){
    height: 48px;
    .el-tabs__nav-wrap{
      height: 100%;
    }
    .el-tabs__nav-scroll{
      height: 100%;
    }
    .el-tabs__nav{
      height: 100%;
      .el-tabs__item {
        height: 100%;
        line-height: 48px;
      }
    }
  }
  .unread-num{
    width:16px;
    height:16px;
    line-height: 16px;
    background:#F55858;
    color:white;
    border-radius: 50%;
    font-size: 12px;
    margin-left: 4px;
    display: inline-block;
    text-align: center;
  }
  .apply-class{
    .apply-tab{
      height: 56px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 1px solid #E6E9F0;
      margin-top: 24px;
      font-weight: 500;
      color: #2A2F3D;
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 24px;
      justify-content: space-between;
    }
  }
  .manage-class {
    padding: 16px;
    background: #F2F4F7;
    margin-top: 24px;
    max-height: 1200px;
    overflow: auto;
    border-radius: 6px;
    .manage-title{
      height: 56px;
      background: #ffffff;
      border-radius: 4px 4px 0px 0px;
      font-weight: 500;
      color: #2A2F3D;
      line-height: 56px;
      padding:0 16px;
    }
    :deep(.el-table){
      padding: 10px;
      .el-table tr{
        height: 56px;
      }
    }
  }
}
:deep(.el-tabs__nav-wrap)::after{
  height: 1px;
  background-color: #F2F4F7;
}
</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
